nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  max-width: 100vw;
}

.background-filter {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.sidebar-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  max-width: 100vw;
  background: #fff;
  box-shadow: 0 25px 50px 100000px rgba(0, 0, 0, 0.25);
}


button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.sidebar-top-container {
  position: absolute;
  left: 70px;
  top: 15px;
  width: calc(100% - 70px);
  height: 50px;
  padding: 0;
  margin: 0;
  list-style: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.button-container {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon {
  height: 26px;
}

.history-container {
  position: absolute;
  top: 80px;
  width: 100%;
  height: 100%;
}

.history-title {
  display: inline;
  margin-left: 25px;
  font-size: 25px;
  font-weight: 500;
}

.clear-button {
  float: right;
  margin-right: 25px;
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.4);
  background: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.history-list {
  position: relative;
  margin: 0;
  margin-top: 15px;
  /* margin-bottom: 25px; */
  padding: 25px;
  padding-top: 10px;
  width: calc(100% - 50px);
  height: calc(100% - 160px);
  overflow: hidden; 
  overflow-y: scroll;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.history-list li {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 0;
  margin-bottom: 20px;
  list-style: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  
  cursor: pointer;
}

.history-item {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-weight: 300;
  text-transform: capitalize;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.history-item::selection {
  color: none;
  background: none;
}

.history-list::-webkit-scrollbar {
  display: none;
}




.settings-container {
  position: absolute;
  top: 80px;
  width: 100%;
  height: 100%;
}

.settings-title {
  display: inline;
  margin-left: 25px;
  font-size: 25px;
  font-weight: 500;
}




.acknowledgements-container {
  position: absolute;
  top: 80px;
  width: 100%;
  height: 100%;
}

.acknowledgements-title {
  display: inline;
  margin-left: 25px;
  font-size: 25px;
  font-weight: 500;
}

.acknowledgements-wrapper {
  position: relative;
  margin: 0;
  margin-top: 15px;
  padding: 25px;
  padding-top: 10px;
  width: calc(100% - 50px);
  height: calc(100% - 160px);

  display: flex;
  flex-direction: column;
}

.acknowledgements-wrapper a {
  margin: 10px 0;
}

.credit {
  background-color: #313D53;
  color: rgba(256, 256, 256, 0.4);
  padding: 10px;
  position: absolute;
  border-top-right-radius: 10px;
  bottom: 0;
  left: 0;
}

.credit a {
  color: white;
  text-decoration: none;
}

.credit a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: yellow;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.credit a:hover {
  color: yellow;
}

.credit a:hover::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
} 