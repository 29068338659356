body {
  transition: background-color 500ms ease;
  overflow: hidden;
}

.main-body {
  position: absolute;
  height: 100vh;
  width: 100vw;
  
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  transition: background-color 500ms ease;
}

.colour-name {
  font-size: 70px;
  text-transform: capitalize;
}

.info-border {
  padding: 10px;
  margin: 10px;
  background-color: rgba(256, 256, 256, 0.6);
  border-radius: 10px;
}

.random-button {
  border: none;
  outline:none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(256, 256, 256, 0.6);
  font-family: 'Rubik', sans-serif;
}

.overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: #313D53;
  pointer-events: none;
}

.credit {
  background-color: #313D53;
  color: rgba(256, 256, 256, 0.4);
  padding: 10px;
  position: fixed;
  border-top-right-radius: 10px;
  bottom: 0;
  left: 0;
  cursor: default;
}

.credit::selection {
  color: none;
  background: none;
}

.credit a::selection {
  color: none;
  background: none;
}

.credit a {
  color: white;
  text-decoration: none;
  z-index: 100;
  cursor: pointer;
}

.credit a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: yellow;
  visibility: hidden;
  transform: scaleX(0);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  transition: all 0.3s ease-in-out 0s;
}

.credit a:hover {
  color: yellow;
}

.credit a:hover::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}