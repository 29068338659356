.search-bar-container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  
  background-color: #fff;
}

.search-bar-items-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: calc(100vw - 100px);
  height: 60px;
  
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.colour-picker {
  margin: 18px;
  height: 24px;
  width: 100%;
}

.hue-picker {
  margin: 18px;
  width: 100% !important;
}

.picker-submit-button {
  height: 30px;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-form {
  margin: 18px;
  height: 24px;
  width: 100%;

}

.search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-size: 1.2rem;
  font-family: 'Rubik', sans-serif;
  user-select: none;
}

.search-icons {
  padding: 0 5px;
  margin: 0 5px;
  border-radius: 10px;
  transition: background-color 700ms ease;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.search-icon {
  margin: 18px;
  cursor: pointer;
  float: right;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}